@font-face {
    font-family: 'Futura PT Light';
    src: url('../fonts/FuturaPT-Light.eot');
    src: url('../fonts/FuturaPT-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/FuturaPT-Light.woff') format('woff'),
    url('../fonts/FuturaPT-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans Light';
    src: url('../fonts/OpenSans-Light.eot');
    src: url('../fonts/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans-Light.woff') format('woff'),
    url('../fonts/OpenSans-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans Regular';
    src: url('../fonts/OpenSans-Regular.eot');
    src: url('../fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans-Regular.woff') format('woff'),
    url('../fonts/OpenSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT Book';
    src: url('../fonts/FuturaPT-Book.eot');
    src: url('../fonts/FuturaPT-Book.eot?#iefix') format('embedded-opentype'),
    url('../fonts/FuturaPT-Book.woff') format('woff'),
    url('../fonts/FuturaPT-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}