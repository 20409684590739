.header {
    background-repeat: no-repeat;
    background-image: url(../img/header-light.png);
    background-position: right top;
    background-size: cover;
    height: 768px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    max-width: 1920px;
    margin: 0 auto;
    transition: background-image .3s;
    @include mediaw(1299px) {
        // background-image: url(../img/header-light-medium.png);
        background-position: -235px top;
    }
    @include mediaw(768px) {
        background-position: calc(100% + 180px) top;
    }
    @include mediaw(768px) {
        background: none;
        height: initial;
        padding: 10px 0;
        min-height: 586px;
    }
    // &::before {
    //     @include mediaw(768px) {
    //         content: " ";
    //         display: block;
    //         width: 100%;
    //         height: 100%;
    //         position: absolute;
    //         left: 0;
    //         top: 0;
    //         background-color: rgba(255,255,255,0.4);
    //         transition: background-color .3s;
    //     }
    // }
    &__title {
        max-width: 411px;
        font-family: $fontFuturaLight;
        font-size: 40px;
        line-height: 60px;
        color: #212121;
        transition: color .3s;
        position: relative;
        @include mediaw(768px) {
            text-align: center;
            // width: 100%;
            max-width: initial;
        }
        @include mediaw(480px) {
            font-size: 27px;    
            line-height: 35px;
        }
        @include mediaw(321px) {
            font-size: 26px; 
        }
        &::after {
            content: " ";
            display: block;
            width: 70px;
            height: 1px;
            background-color: #212121;
            position: absolute;
            bottom: -25px;
            left: 0;
            transition: background-color .3s;
            @include mediaw(768px) {
                display: none;
            }
        }
    }
    .image-block {
        display: none;
        width: calc(100% + 30px);
        transform: translateX(-15px);
        @include mediaw(768px) {
            display: block;
        }
        img {
            display: block;
            margin: 0 auto;
            max-width: 100%;
            max-height: 450px;
        }
    }
    &__list {
        margin-top: 90px;
        font-family: $fontOpenSansRegular;
        font-size: 16px;
        line-height: 40px;
        color: #212121;
        transition: color .3s;
        @include mediaw(992px) {
            margin: 90px 0 170px;
        }
        @include mediaw(768px) {
            font-size: 14px;
            line-height: 38px;
            margin: 0 auto;
            max-width: 320px;
        }
        li {
            padding-left: 50px;
            background-repeat: no-repeat;
            background-position: 7px center;
            &:first-child {
                background-image: url(../img/battery-icon.svg);
                background-position-x: 3px;
            }
            &:nth-child(2) {
                background-image: url(../img/brain-icon.svg);
            }
            &:last-child {
                background-image: url(../img/timer-icon.svg);
            }
        }
    }
    &__price {
        margin-top: 70px;
        max-width: 265px;
        display: flex;
        justify-content: center;
        font-family: $fontOpenSansLight;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        @include mediaw(768px) {
            margin: 20px auto 0;
        }
        @include mediaw(480px) {
            font-size: 14px;
            line-height: 22px;
        }
        .old, .new {
            // width: calc(50% - 20px);
            color: #606060;
            span {
                font-size: 28px;
                line-height: 40px;
                display: inline-block;
                color: #212121;
                transition: color .3s;
            }
        }
        .old span {
            font-family: $fontFuturaLight;
            position: relative;
            &::after {
                content: " ";
                display: block;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                height: 2px;
                width: 100%;
                background-color: #f75252;
            }
        }
        .new span {
            font-family: $fontFuturaBook;
        }
    }
    &__order {
        margin-top: 50px;
        width: 265px;
        height: 50px;
        border-radius: 25px;
        background-color: #212121;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $fontOpenSansRegular;
        font-size: 16px;
        line-height: 40px;
        color: #fff;
        transition: background-color .3s, color .3s;
        @include mediaw(768px) {
            margin: 20px auto 0;
        }
        @include mediaw(480px) {
            margin: 15px auto 0;
        }
    }
    .switcher {
        position: absolute;
        left: 50%;
        bottom: 33px;
        transform: translateX(-50%);
        width: 40px;
        height: 3px;
        background-color: #fff;
        cursor: pointer;
        transition: background-color .3s;
        z-index: 2;
        @include mediaw(1299px) {
            display: none;
        }
        .labels {
            position: absolute;
            top: 12px;
            left: -48px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            width: 132px;
            color: #C0BFBF;
            font-family: $fontOpenSansLight;
            font-size: 12px;
        }
        .circle {
            width: 20px;
            height: 20px;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
            background-color: #212121;
            position: absolute;
            left: -10px;
            top: -9px;
            border-radius: 50%;
            cursor: pointer;
            transition: background-color .3s, left .3s;
        }
        &::before {
            content: " ";
            display: block;
            width: 27px;
            height: 27px;
            background-repeat: no-repeat;
            background-image: url(../img/light-icon.svg);
            background-size: cover;
            position: absolute;
            top: -13px;
            left: -50px;
        }
        &::after {
            content: " ";
            display: block;
            width: 12.4px;
            height: 15px;
            background-repeat: no-repeat;
            background-image: url(../img/dark-icon.svg);
            background-size: cover;
            position: absolute;
            right: -40px;
            top: -7px;
            transition: opacity .3s;
            @include mediaw(480px) {
                transform: rotate(-90deg);
            }
        }
    }
    &__arrow {
        font-family: $fontOpenSansLight;
        font-size: 20px;
        line-height: 28px;
        color: #606060;
        max-width: 120px;
        position: absolute;
        top: 90px;
        right: 280px;
        transition: color .3s;
        @include mediaw(1830px) {
            right: 270px;
        }
        @include mediaw(1700px) {
            right: 270px;
        }
        @include mediaw(1600px) {
            top: 75px;
            right: 245px;
        }
        @include mediaw(1299px) {
            display: none;
        }
        // @include mediaw(768px) {
        //     display: none;
        // }
        &::before {
            content: " ";
            display: block;
            position: absolute;
            left: -53px;
            bottom: -36px;
            width: 51.9px;
            height: 51.9px;
            background-repeat: no-repeat;
            background-size: cover;
            background-image: url(../img/yellow-arrow.svg);
        }
    }
    &.switcher-active {
        &.header {
            background-image: url(../img/header-dark.png);
        }
        .header__title, .header__list, .header__price .new span, .header__price .old span {
            color: #fff;
        }
        .header__arrow {
            color: #909090;
        }
        .header__title::after, .circle {
            background-color: #fff;
        }
        .header__order {
            background-color: #fff;
            color: #212121;
        }
        .header .switcher::after {
            opacity: 0.5;
        }
        .switcher {
            background-color: #606060;
        }
        .circle {
            background-color: #fff;
            left: calc(100% - 10px);
        }
        .container {
            @include mediaw(1300px) {
                display: flex;
            }
            @include mediaw(768px) {
                display: initial;
            }
        }
        // .header__content {
        //     @include mediaw(1300px) {
        //         background-color: rgba(0,0,0,.5);
        //     }
        //     @include mediaw(768px) {
        //         background-color: initial;
        //     }
        // }
        &::before {
            background-color: rgba(0, 0, 0, 0.2);
        }
    }
}