@import 'normalize';

@import 'variebles';

@import 'fonts';
@import 'responce';
@import 'header';
@import 'home';
@import 'footer';

body {
    overflow-x: hidden;
}

.container {
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
    padding: 0 15px;
}

.section-title {
    font-family: $fontFuturaLight;
    font-size: 34px;
    line-height: 40px;
    color: #212121;
    text-align: center;
    position: relative;
    margin-bottom: 75px;
    &::after {
        content: " ";
        display: block;
        height: 1px;
        width: 70px;
        background-color: #212121;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -20px;
        @include mediaw(480px) {
            bottom: -10px;
        }
    }
    @include mediaw(480px) {
        font-size: 27px;    
        line-height: 35px;
        margin-bottom: 35px;
    }
}

.price__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 555px;
    margin: 60px auto 0;
    // @include mediaw(600px) {
    //     flex-wrap: wrap;
    // }
    @include mediaw(480px) {
        margin: 30px auto 0;
    }
    &-block {
        width: 350px;
        height: 100px;
        border-radius: 50px;
        border: solid 1px rgba(234, 234, 234, 0.3);
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        // position: relative;
        @include mediaw(600px) {
            margin: 0 auto;
        }
        @include mediaw(480px) {
            width: calc(50% - 5px);
            height: 50px;
        }
        // &::after {
        //     content: " ";
        //     display: block;
        //     height: 100%;
        //     width: 1px;
        //     position: absolute;
        //     left: 50%;
        //     transform: translateX(-50%);
        //     background-color: rgba(234, 234, 234, 0.3);
        // }
        .old, .new {
            font-family: $fontOpenSansLight;
            font-size: 16px;
            line-height: 24px;
            // width: 50%;
            @include mediaw(360px) {
                font-size: 14px;
                line-height: 22px;
            }
            span {
                font-size: 28px;
                line-height: 40px;
                @include mediaw(480px) {
                    font-size: 24px;
                }
                @include mediaw(374px) {
                    font-size: 19px;
                }
            }
        }
        .old {
            padding: 0 20px 0 40px;
            @include mediaw(400px) {
                padding: 0 10px 0 30px;
            }
            @include mediaw(360px) {
                padding: 0 10px 0 20px;
            }
            span {
                display: inline-block;
                position: relative;
                font-family: $fontFuturaLight;
                &::after {
                    content: " ";
                    display: block;
                    height: 2px;
                    width: 100%;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                    background-color: #f75252;
                }
            }
        }
        .new {
            padding: 0 20px;
            @include mediaw(400px) {
                padding: 0 10px;
            }
            span {
                font-family: $fontFuturaBook;
            }
        }
    }
    .price__order {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        color: #212121;
        width: 190px;
        height: 50px;
        border-radius: 25px;
        font-family: $fontOpenSansRegular;
        @include mediaw(600px) {
            margin-left: 10px;
        }
        @include mediaw(480px) {
            width: calc(50% - 5px);
        }
    }
}