.description {
    padding: 150px 0;
    @include mediaw(768px) {
        padding: 50px 0;
    }
    &__row {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @include mediaw(1092px) {
            justify-content: space-around;
        }
        @include mediaw(600px) {
            justify-content: space-between;
        }
    }
    &__item {
        max-width: 262px;
        padding: 0 5px;
        @include mediaw(768px) {
            width: calc(50% - 10px);
        }
        .image-block {
            max-height: 262px;
            img {
                display: block;
                max-width: 100%;
                max-height: 100%;
                margin: 0 auto;
            }
        }
        .text {
            text-align: center;
            font-family: $fontOpenSansRegular;
            font-size: 16px;
            line-height: 22px;
            color: #404040;
            margin-top: 30px;
            @include mediaw(480px) {
                margin-top: 5px;
                font-size: 14px;
            }
        }
        &:nth-child(5), &:nth-child(6), &:nth-child(7), &:last-child {
            margin-top: 40px;
            @include mediaw(480px) {
                margin-top: 20px;
            }
        }
        &:nth-child(3) {
            @include mediaw(830px) {
                margin-top: 40px;
            }
            @include mediaw(480px) {
                margin-top: 20px;
            }
        }
        &:nth-child(4) {
            @include mediaw(1092px) {
                margin-top: 40px;
            }
            @include mediaw(480px) {
                margin-top: 20px;
            }
        }
    }

    .price__row-block {
        border-color: #eaeaea;
        color: #606060;
        span {
            color: #212121;
        }
        &::after {
            background-color: #eaeaea;
        }
    }
    .price__order {
        background-color: #212121 !important;
        color: #fff !important;
    }
}

.look {
    background-color: #212121;
    padding: 60px 0;
    @include mediaw(480px) {
        padding: 45px 0;
    }
    &__title {
        color: #fff !important;
        margin-bottom: 55px !important;
        &::after {
            background-color: #fff !important;
        }
    }
    &__video {
        width: 850px;
        margin: 0 auto;
        height: 480px;
        @include mediaw(900px) {
            width: 100%;
        }
        @include mediaw(685px) {
            height: 360px;
        }
        @include mediaw(525px) {
            height: 205px;
        }
        iframe {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
}

.reviews {
    padding: 150px 0;
    background-color: #fff;
    @include mediaw(768px) {
        padding: 75px 0;
    }
    @include mediaw(480px) {
        padding: 35px 0;
    }
    &__slider--wrapper {
        position: relative;
        @include mediaw(1300px) {
            padding: 0 45px;
        }
        @include mediaw(480px) {
            padding: 0 20px;
        }
        .reviews__buttons {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -60px;
            width: calc(100% + 120px);
            display: flex;
            justify-content: space-between;
            z-index: 1;
            @include mediaw(1300px) {
                left: -10px;
                width: calc(100% + 20px);
            }
            @include mediaw(480px) {
                left: -5px;
                width: calc(100% + 10px);
            }
            .reviews__buttons-prev, .reviews__buttons-next {
                width: 30px;
                height: 50px;
                background-repeat: no-repeat;
                background-size: cover;
                cursor: pointer;
                @include mediaw(480px) {
                    width: 15px;
                    height: 25px;
                }
            }
            .reviews__buttons-prev {
                background-image: url(../img/arrow-left.svg);
            }
            .reviews__buttons-next {
                background-image: url(../img/arrow-right.svg);
            }
        }
    }
    &__slider {
        z-index: 1;
    }
    .slick-list {
        margin: 0 -15px;
    }
    .slick-dots {
        @include mediaw(480px) {
            bottom: -10px;
        }
        li {
            width: 15px;
            height: 15px;
            margin: 0 10px;
            @include mediaw(480px) {
                margin: 0 5px;
            }
            button {
                width: 15px;
                height: 15px;
                &::before {
                    font-size: 15px;
                    width: 15px;
                    height: 15px;
                    color: #cacaca;
                    opacity: 1;
                    @include mediaw(480px) {
                        font-size: 13px;
                    }
                }
            }
            &.slick-active {
                button {
                    &::before {
                        color: #212121;
                    }
                }
            }
        }
    }
    &__slide {
        box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16);
        background-color: #ffffff;
        margin: 0 15px 20px;
        padding-bottom: 30px;
        @include mediaw(480px) {
            padding-bottom: 15px;
        }
        .image-block {
            img {
                display: block;
                max-width: 100%;
                max-height: 100%;
                margin: 0 auto;
            }
        }
        .name, .text {
            padding: 0 30px;
            @include mediaw(480px) {
                padding: 0 15px;
            }
        }
        .name {
            margin-top: 30px;
            color: #212121;
            font-family: $fontFuturaBook;
            font-size: 28px;
            line-height: 40px;
            text-align: center;
            @include mediaw(480px) {
                margin-top: 15px;
                font-size: 24px;
                line-height: 36px;
            }
        }
        .text {
            margin-top: 20px;
            font-family: $fontOpenSansRegular;
            font-size: 16px;
            line-height: 24px;
            color: #404040;
            @include mediaw(480px) {
                margin-top: 10px;
                font-size: 14px;
            }
        }
    }
    .price__row-block {
        border-color: #eaeaea;
        color: #606060;
        span {
            color: #212121;
        }
        &::after {
            background-color: #eaeaea;
        }
    }
    .price__order {
        background-color: #212121 !important;
        color: #fff !important;
    }
}

.characteristic {
    background-color: #fff;
    padding-bottom: 150px;
    @include mediaw(480px) {
        padding: 50px 0;
    }
    .container {
        position: relative;
        &::before {
            content: " ";
            display: block;
            width: 300px;
            height: 455px;
            background-repeat: no-repeat;
            background-image: url(../img/pink-lamp.png);
            background-size: cover;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -35px;
            @include mediaw(1111px) {
                display: none;
            }
        }
        &::after {
            content: " ";
            display: block;
            width: 300px;
            height: 455px;
            background-repeat: no-repeat;
            background-image: url(../img/white-lamp.png);
            background-size: cover;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: -35px;
            @include mediaw(1111px) {
                display: none;
            }
        }
    }
    &__list {
        max-width: 555px;
        margin: 0 auto;
        font-family: $fontOpenSansRegular;
        font-size: 16px;
        line-height: 22px;
        color: #404040;
        @include mediaw(480px) {
            font-size: 14px;
            line-height: 20px;
        }
        li {
            display: flex;
            justify-content: space-between;
            padding: 4px 15px;
            background-color: #f4f4f4;
            &:nth-child(2n) {
                background-color: #fff;
            }
            p:last-child {
                max-width: 330px;
                @include mediaw(480px) {
                    max-width: 125px;
                }
            }
        }
    }
    .price__row-block {
        border-color: #eaeaea;
        color: #606060;
        span {
            color: #212121;
        }
        &::after {
            background-color: #eaeaea;
        }
    }
    .price__order {
        background-color: #212121 !important;
        color: #fff !important;
    }
}

.images {
    &__row {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    &__item {
        width: 25%;
        @include mediaw(768px) {
            width: 50%;
        }
        img {
            display: block;
            width: 100%;
            max-width: 100%;
            max-height: 100%;
            margin: 0 auto;
        }
    }
}

.form {
    background-repeat: no-repeat;
    background-image: url(../img/form-bg.png);
    background-size: cover;
    position: relative;
    color: #fff;
    padding: 120px 0 300px;
    @include mediaw(876px) {
        padding: 120px 0 400px;
    }
    @include mediaw(584px) {
        padding: 120px 0 450px;
    }
    @include mediaw(480px) {
        padding: 60px 0 450px;
    }
    @include mediaw(400px) {
        padding: 60px 0 450px;
    }
    &__title {
        color: #fff !important;
        &::after {
            background-color: #fff !important;
        }
    }
    &::after {
        content: " ";
        position: absolute;
        left: 0;
        top: 0;
        -webkit-backdrop-filter: blur(14.4px);
        backdrop-filter: blur(14.4px);
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(33, 33, 33, 0.58);
    }
    .container {
        position: relative;
        z-index: 1;
    }
    form {
        display: block;
        .input-wrapper {
            position: relative;
            width: calc(50% - 15px);
            @include mediaw(480px) {
                width: 100%;
            }
            &:last-child {
                @include mediaw(480px) {
                    margin-top: 15px;
                }
            }
        }
        .input-wrapper .floating-label {
            position: absolute;
            pointer-events: none;
            top: 30px;
            left: 0;
            transition: 0.2s ease all;
            font-family: $fontOpenSansRegular;
            font-size: 16px;
            line-height: 40px;
            @include mediaw(480px) {
                font-size: 14px;
                line-height: 38px;
            }
        }
        .input-wrapper input:focus ~ .floating-label,
        .input-wrapper input:not(:focus):valid ~ .floating-label{
            top: 0;
        }
        .inputs-wrapper {
            display: flex;
            justify-content: space-between;
            @include mediaw(480px) {
                flex-wrap: wrap;
            }
        }
        input {
            display: block;
            width: 100%;
            &:not([type="submit"]) {
                height: 100px;
                background-color: transparent;
                border-bottom: 1px solid #fff;
                font-family: $fontFuturaBook;
                font-size: 25px;
                line-height: 40px;
                color: #fff;
                padding-top: 25px;
                @include mediaw(480px) {
                    padding-top: 15px;
                    height: 75px;
                }
            }
            &[type="submit"] {
                background-color: #212121;
                font-size: 16px;
                line-height: 40px;
                color: #fff;
                width: 190px;
                height: 50px;
                border-radius: 25px;
                font-family: $fontOpenSansRegular;
                cursor: pointer;
                @include mediaw(600px) {
                    margin-left: 10px;
                    width: calc(50% - 5px);
                }
            }
            &[type="radio"] {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }
        }
        .color--wrapper {
            display: flex;
            justify-content: center;
            font-family: $fontOpenSansRegular;
            font-size: 16px;
            line-height: 40px;
            color: #fff;
            margin-top: 45px;
            @include mediaw(480px) {
                font-size: 13px;
                line-height: 20px;
            }
            @include mediaw(374px) {
                flex-wrap: wrap;
            }
            p {
                margin-right: 30px;
                @include mediaw(480px) {
                    margin-right: 15px;
                }
                @include mediaw(374px) {
                    margin-right: initial;
                    text-align: center;
                    width: 100%;
                }
            }
            .color-item {
                padding: 5px 0 5px 40px;
                position: relative;
                cursor: pointer;
                @include mediaw(480px) {
                    padding: 5px 0 5px 30px;
                }
                @include mediaw(374px) {
                    display: inline-block;
                    margin: 10px;
                }
                input:checked ~ .radioBtn {
                    position: absolute;
                    top: 13px;
                    left: 12px;
                    display: block; 
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background-color: #212121;
                    @include mediaw(480px) {
                        left: 9.5px;
                    }
                }
                &::before {
                    content: " ";
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                    display: block;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    @include mediaw(480px) {
                        width: 25px;
                        height: 25px;
                    }
                }
                &:first-child {
                    margin-right: 30px;
                    @include mediaw(480px) {
                        margin-right: 5px;
                    }
                    &::before { 
                        background-color: #f477c0;
                    }
                }
                &:last-child {
                    &::before {
                        background-color: #fff;
                    }
                }
            }
        }
    }
    .advantages {
        position: absolute;
        bottom: 0;
        background-color: rgba(33, 33, 33, 0.32);
        padding: 40px 0;
        z-index: 1;
        width: 100%;
        .container {
            display: flex;
            justify-content: space-between;
            @include mediaw(768px) {
                flex-wrap: wrap;
            }
        }
        &__item {
            width: calc(33.33334% - 30px);
            padding-left: 120px;
            position: relative;
            background-repeat: no-repeat;
            @include mediaw(768px) {
                width: 100%;
                padding-left: 90px;
            }
            &:first-child {
                background-image: url(../img/delivery-icon.svg);
                background-position: 20px 35px;
                @include mediaw(768px) {
                    background-position: 12px 23px;
                    background-size: 55px;
                }
            }
            &:nth-child(2) {
                background-image: url(../img/wallet-icon.svg);
                background-position: 27px 23px;
                @include mediaw(768px) {
                    background-position: 17px 13px;
                    background-size: 44px;
                    margin-top: 20px;
                }
            }
            &:last-child {
                background-image: url(../img/package-icon.svg);
                background-position: 32px 28px;
                @include mediaw(768px) {
                    background-position: 20px 16px;
                    margin-top: 20px;
                }
            }
            &::before {
                content: " ";
                display: block;
                width: 100px;
                height: 100px;
                border-radius: 50%;
                border: solid 2px rgba(255,255,255,0.4);
                position: absolute;
                left: 0;
                top: 0;
                @include mediaw(768px) {
                    width: 75px;
                    height: 75px;
                }
            }
            &-title {
                font-family: $fontFuturaBook;
                font-size: 24px;
                line-height: 40px;
                @include mediaw(480px) {
                    font-size: 22px;
                    line-height: 38px;
                }
            }
            &-description {
                margin-top: 10px;
                font-family: $fontOpenSansRegular;
                font-size: 16px;
                line-height: 22px;
                @include mediaw(480px) {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
    }
}