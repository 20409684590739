*, *::before, *::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent; }

a {
  color: inherit;
  text-decoration: none; }

ul {
  list-style: none; }

input, button {
  border: none; }
  input:focus, button:focus {
    outline: none; }

body.overflow-active {
  overflow: hidden; }

@font-face {
  font-family: 'Futura PT Light';
  src: url("../fonts/FuturaPT-Light.eot");
  src: url("../fonts/FuturaPT-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaPT-Light.woff") format("woff"), url("../fonts/FuturaPT-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans Light';
  src: url("../fonts/OpenSans-Light.eot");
  src: url("../fonts/OpenSans-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans-Light.woff") format("woff"), url("../fonts/OpenSans-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans Regular';
  src: url("../fonts/OpenSans-Regular.eot");
  src: url("../fonts/OpenSans-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans-Regular.woff") format("woff"), url("../fonts/OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Futura PT Book';
  src: url("../fonts/FuturaPT-Book.eot");
  src: url("../fonts/FuturaPT-Book.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaPT-Book.woff") format("woff"), url("../fonts/FuturaPT-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

.header {
  background-repeat: no-repeat;
  background-image: url(../img/header-light.png);
  background-position: right top;
  background-size: cover;
  height: 768px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  -webkit-transition: background-image .3s;
  -o-transition: background-image .3s;
  transition: background-image .3s; }
  @media only screen and (max-width: 1299px) {
    .header {
      background-position: -235px top; } }
  @media only screen and (max-width: 768px) {
    .header {
      background-position: calc(100% + 180px) top; } }
  @media only screen and (max-width: 768px) {
    .header {
      background: none;
      height: initial;
      padding: 10px 0;
      min-height: 586px; } }
  .header__title {
    max-width: 411px;
    font-family: "Futura PT Light";
    font-size: 40px;
    line-height: 60px;
    color: #212121;
    -webkit-transition: color .3s;
    -o-transition: color .3s;
    transition: color .3s;
    position: relative; }
    @media only screen and (max-width: 768px) {
      .header__title {
        text-align: center;
        max-width: initial; } }
    @media only screen and (max-width: 480px) {
      .header__title {
        font-size: 27px;
        line-height: 35px; } }
    @media only screen and (max-width: 321px) {
      .header__title {
        font-size: 26px; } }
    .header__title::after {
      content: " ";
      display: block;
      width: 70px;
      height: 1px;
      background-color: #212121;
      position: absolute;
      bottom: -25px;
      left: 0;
      -webkit-transition: background-color .3s;
      -o-transition: background-color .3s;
      transition: background-color .3s; }
      @media only screen and (max-width: 768px) {
        .header__title::after {
          display: none; } }
  .header .image-block {
    display: none;
    width: calc(100% + 30px);
    -webkit-transform: translateX(-15px);
        -ms-transform: translateX(-15px);
            transform: translateX(-15px); }
    @media only screen and (max-width: 768px) {
      .header .image-block {
        display: block; } }
    .header .image-block img {
      display: block;
      margin: 0 auto;
      max-width: 100%;
      max-height: 450px; }
  .header__list {
    margin-top: 90px;
    font-family: "Open Sans Regular";
    font-size: 16px;
    line-height: 40px;
    color: #212121;
    -webkit-transition: color .3s;
    -o-transition: color .3s;
    transition: color .3s; }
    @media only screen and (max-width: 992px) {
      .header__list {
        margin: 90px 0 170px; } }
    @media only screen and (max-width: 768px) {
      .header__list {
        font-size: 14px;
        line-height: 38px;
        margin: 0 auto;
        max-width: 320px; } }
    .header__list li {
      padding-left: 50px;
      background-repeat: no-repeat;
      background-position: 7px center; }
      .header__list li:first-child {
        background-image: url(../img/battery-icon.svg);
        background-position-x: 3px; }
      .header__list li:nth-child(2) {
        background-image: url(../img/brain-icon.svg); }
      .header__list li:last-child {
        background-image: url(../img/timer-icon.svg); }
  .header__price {
    margin-top: 70px;
    max-width: 265px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    font-family: "Open Sans Light";
    font-size: 16px;
    line-height: 24px;
    text-align: center; }
    @media only screen and (max-width: 768px) {
      .header__price {
        margin: 20px auto 0; } }
    @media only screen and (max-width: 480px) {
      .header__price {
        font-size: 14px;
        line-height: 22px; } }
    .header__price .old, .header__price .new {
      color: #606060; }
      .header__price .old span, .header__price .new span {
        font-size: 28px;
        line-height: 40px;
        display: inline-block;
        color: #212121;
        -webkit-transition: color .3s;
        -o-transition: color .3s;
        transition: color .3s; }
    .header__price .old span {
      font-family: "Futura PT Light";
      position: relative; }
      .header__price .old span::after {
        content: " ";
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
                transform: translateY(-50%);
        height: 2px;
        width: 100%;
        background-color: #f75252; }
    .header__price .new span {
      font-family: "Futura PT Book"; }
  .header__order {
    margin-top: 50px;
    width: 265px;
    height: 50px;
    border-radius: 25px;
    background-color: #212121;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    font-family: "Open Sans Regular";
    font-size: 16px;
    line-height: 40px;
    color: #fff;
    -webkit-transition: background-color .3s, color .3s;
    -o-transition: background-color .3s, color .3s;
    transition: background-color .3s, color .3s; }
    @media only screen and (max-width: 768px) {
      .header__order {
        margin: 20px auto 0; } }
    @media only screen and (max-width: 480px) {
      .header__order {
        margin: 15px auto 0; } }
  .header .switcher {
    position: absolute;
    left: 50%;
    bottom: 33px;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 40px;
    height: 3px;
    background-color: #fff;
    cursor: pointer;
    -webkit-transition: background-color .3s;
    -o-transition: background-color .3s;
    transition: background-color .3s;
    z-index: 2; }
    @media only screen and (max-width: 1299px) {
      .header .switcher {
        display: none; } }
    .header .switcher .labels {
      position: absolute;
      top: 12px;
      left: -48px;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      width: 132px;
      color: #C0BFBF;
      font-family: "Open Sans Light";
      font-size: 12px; }
    .header .switcher .circle {
      width: 20px;
      height: 20px;
      -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
              box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
      background-color: #212121;
      position: absolute;
      left: -10px;
      top: -9px;
      border-radius: 50%;
      cursor: pointer;
      -webkit-transition: background-color .3s, left .3s;
      -o-transition: background-color .3s, left .3s;
      transition: background-color .3s, left .3s; }
    .header .switcher::before {
      content: " ";
      display: block;
      width: 27px;
      height: 27px;
      background-repeat: no-repeat;
      background-image: url(../img/light-icon.svg);
      background-size: cover;
      position: absolute;
      top: -13px;
      left: -50px; }
    .header .switcher::after {
      content: " ";
      display: block;
      width: 12.4px;
      height: 15px;
      background-repeat: no-repeat;
      background-image: url(../img/dark-icon.svg);
      background-size: cover;
      position: absolute;
      right: -40px;
      top: -7px;
      -webkit-transition: opacity .3s;
      -o-transition: opacity .3s;
      transition: opacity .3s; }
      @media only screen and (max-width: 480px) {
        .header .switcher::after {
          -webkit-transform: rotate(-90deg);
              -ms-transform: rotate(-90deg);
                  transform: rotate(-90deg); } }
  .header__arrow {
    font-family: "Open Sans Light";
    font-size: 20px;
    line-height: 28px;
    color: #606060;
    max-width: 120px;
    position: absolute;
    top: 90px;
    right: 280px;
    -webkit-transition: color .3s;
    -o-transition: color .3s;
    transition: color .3s; }
    @media only screen and (max-width: 1830px) {
      .header__arrow {
        right: 270px; } }
    @media only screen and (max-width: 1700px) {
      .header__arrow {
        right: 270px; } }
    @media only screen and (max-width: 1600px) {
      .header__arrow {
        top: 75px;
        right: 245px; } }
    @media only screen and (max-width: 1299px) {
      .header__arrow {
        display: none; } }
    .header__arrow::before {
      content: " ";
      display: block;
      position: absolute;
      left: -53px;
      bottom: -36px;
      width: 51.9px;
      height: 51.9px;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url(../img/yellow-arrow.svg); }
  .header.switcher-active.header {
    background-image: url(../img/header-dark.png); }
  .header.switcher-active .header__title, .header.switcher-active .header__list, .header.switcher-active .header__price .new span, .header.switcher-active .header__price .old span {
    color: #fff; }
  .header.switcher-active .header__arrow {
    color: #909090; }
  .header.switcher-active .header__title::after, .header.switcher-active .circle {
    background-color: #fff; }
  .header.switcher-active .header__order {
    background-color: #fff;
    color: #212121; }
  .header.switcher-active .header .switcher::after {
    opacity: 0.5; }
  .header.switcher-active .switcher {
    background-color: #606060; }
  .header.switcher-active .circle {
    background-color: #fff;
    left: calc(100% - 10px); }
  @media only screen and (max-width: 1300px) {
    .header.switcher-active .container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; } }
  @media only screen and (max-width: 768px) {
    .header.switcher-active .container {
      display: initial; } }
  .header.switcher-active::before {
    background-color: rgba(0, 0, 0, 0.2); }

.description {
  padding: 150px 0; }
  @media only screen and (max-width: 768px) {
    .description {
      padding: 50px 0; } }
  .description__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    @media only screen and (max-width: 1092px) {
      .description__row {
        -ms-flex-pack: distribute;
            justify-content: space-around; } }
    @media only screen and (max-width: 600px) {
      .description__row {
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between; } }
  .description__item {
    max-width: 262px;
    padding: 0 5px; }
    @media only screen and (max-width: 768px) {
      .description__item {
        width: calc(50% - 10px); } }
    .description__item .image-block {
      max-height: 262px; }
      .description__item .image-block img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        margin: 0 auto; }
    .description__item .text {
      text-align: center;
      font-family: "Open Sans Regular";
      font-size: 16px;
      line-height: 22px;
      color: #404040;
      margin-top: 30px; }
      @media only screen and (max-width: 480px) {
        .description__item .text {
          margin-top: 5px;
          font-size: 14px; } }
    .description__item:nth-child(5), .description__item:nth-child(6), .description__item:nth-child(7), .description__item:last-child {
      margin-top: 40px; }
      @media only screen and (max-width: 480px) {
        .description__item:nth-child(5), .description__item:nth-child(6), .description__item:nth-child(7), .description__item:last-child {
          margin-top: 20px; } }
    @media only screen and (max-width: 830px) {
      .description__item:nth-child(3) {
        margin-top: 40px; } }
    @media only screen and (max-width: 480px) {
      .description__item:nth-child(3) {
        margin-top: 20px; } }
    @media only screen and (max-width: 1092px) {
      .description__item:nth-child(4) {
        margin-top: 40px; } }
    @media only screen and (max-width: 480px) {
      .description__item:nth-child(4) {
        margin-top: 20px; } }
  .description .price__row-block {
    border-color: #eaeaea;
    color: #606060; }
    .description .price__row-block span {
      color: #212121; }
    .description .price__row-block::after {
      background-color: #eaeaea; }
  .description .price__order {
    background-color: #212121 !important;
    color: #fff !important; }

.look {
  background-color: #212121;
  padding: 60px 0; }
  @media only screen and (max-width: 480px) {
    .look {
      padding: 45px 0; } }
  .look__title {
    color: #fff !important;
    margin-bottom: 55px !important; }
    .look__title::after {
      background-color: #fff !important; }
  .look__video {
    width: 850px;
    margin: 0 auto;
    height: 480px; }
    @media only screen and (max-width: 900px) {
      .look__video {
        width: 100%; } }
    @media only screen and (max-width: 685px) {
      .look__video {
        height: 360px; } }
    @media only screen and (max-width: 525px) {
      .look__video {
        height: 205px; } }
    .look__video iframe {
      display: block;
      width: 100%;
      height: 100%; }

.reviews {
  padding: 150px 0;
  background-color: #fff; }
  @media only screen and (max-width: 768px) {
    .reviews {
      padding: 75px 0; } }
  @media only screen and (max-width: 480px) {
    .reviews {
      padding: 35px 0; } }
  .reviews__slider--wrapper {
    position: relative; }
    @media only screen and (max-width: 1300px) {
      .reviews__slider--wrapper {
        padding: 0 45px; } }
    @media only screen and (max-width: 480px) {
      .reviews__slider--wrapper {
        padding: 0 20px; } }
    .reviews__slider--wrapper .reviews__buttons {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
              transform: translateY(-50%);
      left: -60px;
      width: calc(100% + 120px);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      z-index: 1; }
      @media only screen and (max-width: 1300px) {
        .reviews__slider--wrapper .reviews__buttons {
          left: -10px;
          width: calc(100% + 20px); } }
      @media only screen and (max-width: 480px) {
        .reviews__slider--wrapper .reviews__buttons {
          left: -5px;
          width: calc(100% + 10px); } }
      .reviews__slider--wrapper .reviews__buttons .reviews__buttons-prev, .reviews__slider--wrapper .reviews__buttons .reviews__buttons-next {
        width: 30px;
        height: 50px;
        background-repeat: no-repeat;
        background-size: cover;
        cursor: pointer; }
        @media only screen and (max-width: 480px) {
          .reviews__slider--wrapper .reviews__buttons .reviews__buttons-prev, .reviews__slider--wrapper .reviews__buttons .reviews__buttons-next {
            width: 15px;
            height: 25px; } }
      .reviews__slider--wrapper .reviews__buttons .reviews__buttons-prev {
        background-image: url(../img/arrow-left.svg); }
      .reviews__slider--wrapper .reviews__buttons .reviews__buttons-next {
        background-image: url(../img/arrow-right.svg); }
  .reviews__slider {
    z-index: 1; }
  .reviews .slick-list {
    margin: 0 -15px; }
  @media only screen and (max-width: 480px) {
    .reviews .slick-dots {
      bottom: -10px; } }
  .reviews .slick-dots li {
    width: 15px;
    height: 15px;
    margin: 0 10px; }
    @media only screen and (max-width: 480px) {
      .reviews .slick-dots li {
        margin: 0 5px; } }
    .reviews .slick-dots li button {
      width: 15px;
      height: 15px; }
      .reviews .slick-dots li button::before {
        font-size: 15px;
        width: 15px;
        height: 15px;
        color: #cacaca;
        opacity: 1; }
        @media only screen and (max-width: 480px) {
          .reviews .slick-dots li button::before {
            font-size: 13px; } }
    .reviews .slick-dots li.slick-active button::before {
      color: #212121; }
  .reviews__slide {
    -webkit-box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16);
            box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    margin: 0 15px 20px;
    padding-bottom: 30px; }
    @media only screen and (max-width: 480px) {
      .reviews__slide {
        padding-bottom: 15px; } }
    .reviews__slide .image-block img {
      display: block;
      max-width: 100%;
      max-height: 100%;
      margin: 0 auto; }
    .reviews__slide .name, .reviews__slide .text {
      padding: 0 30px; }
      @media only screen and (max-width: 480px) {
        .reviews__slide .name, .reviews__slide .text {
          padding: 0 15px; } }
    .reviews__slide .name {
      margin-top: 30px;
      color: #212121;
      font-family: "Futura PT Book";
      font-size: 28px;
      line-height: 40px;
      text-align: center; }
      @media only screen and (max-width: 480px) {
        .reviews__slide .name {
          margin-top: 15px;
          font-size: 24px;
          line-height: 36px; } }
    .reviews__slide .text {
      margin-top: 20px;
      font-family: "Open Sans Regular";
      font-size: 16px;
      line-height: 24px;
      color: #404040; }
      @media only screen and (max-width: 480px) {
        .reviews__slide .text {
          margin-top: 10px;
          font-size: 14px; } }
  .reviews .price__row-block {
    border-color: #eaeaea;
    color: #606060; }
    .reviews .price__row-block span {
      color: #212121; }
    .reviews .price__row-block::after {
      background-color: #eaeaea; }
  .reviews .price__order {
    background-color: #212121 !important;
    color: #fff !important; }

.characteristic {
  background-color: #fff;
  padding-bottom: 150px; }
  @media only screen and (max-width: 480px) {
    .characteristic {
      padding: 50px 0; } }
  .characteristic .container {
    position: relative; }
    .characteristic .container::before {
      content: " ";
      display: block;
      width: 300px;
      height: 455px;
      background-repeat: no-repeat;
      background-image: url(../img/pink-lamp.png);
      background-size: cover;
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
              transform: translateY(-50%);
      left: -35px; }
      @media only screen and (max-width: 1111px) {
        .characteristic .container::before {
          display: none; } }
    .characteristic .container::after {
      content: " ";
      display: block;
      width: 300px;
      height: 455px;
      background-repeat: no-repeat;
      background-image: url(../img/white-lamp.png);
      background-size: cover;
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
              transform: translateY(-50%);
      right: -35px; }
      @media only screen and (max-width: 1111px) {
        .characteristic .container::after {
          display: none; } }
  .characteristic__list {
    max-width: 555px;
    margin: 0 auto;
    font-family: "Open Sans Regular";
    font-size: 16px;
    line-height: 22px;
    color: #404040; }
    @media only screen and (max-width: 480px) {
      .characteristic__list {
        font-size: 14px;
        line-height: 20px; } }
    .characteristic__list li {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      padding: 4px 15px;
      background-color: #f4f4f4; }
      .characteristic__list li:nth-child(2n) {
        background-color: #fff; }
      .characteristic__list li p:last-child {
        max-width: 330px; }
        @media only screen and (max-width: 480px) {
          .characteristic__list li p:last-child {
            max-width: 125px; } }
  .characteristic .price__row-block {
    border-color: #eaeaea;
    color: #606060; }
    .characteristic .price__row-block span {
      color: #212121; }
    .characteristic .price__row-block::after {
      background-color: #eaeaea; }
  .characteristic .price__order {
    background-color: #212121 !important;
    color: #fff !important; }

.images__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }

.images__item {
  width: 25%; }
  @media only screen and (max-width: 768px) {
    .images__item {
      width: 50%; } }
  .images__item img {
    display: block;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto; }

.form {
  background-repeat: no-repeat;
  background-image: url(../img/form-bg.png);
  background-size: cover;
  position: relative;
  color: #fff;
  padding: 120px 0 300px; }
  @media only screen and (max-width: 876px) {
    .form {
      padding: 120px 0 400px; } }
  @media only screen and (max-width: 584px) {
    .form {
      padding: 120px 0 450px; } }
  @media only screen and (max-width: 480px) {
    .form {
      padding: 60px 0 450px; } }
  @media only screen and (max-width: 400px) {
    .form {
      padding: 60px 0 450px; } }
  .form__title {
    color: #fff !important; }
    .form__title::after {
      background-color: #fff !important; }
  .form::after {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    -webkit-backdrop-filter: blur(14.4px);
    backdrop-filter: blur(14.4px);
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(33, 33, 33, 0.58); }
  .form .container {
    position: relative;
    z-index: 1; }
  .form form {
    display: block; }
    .form form .input-wrapper {
      position: relative;
      width: calc(50% - 15px); }
      @media only screen and (max-width: 480px) {
        .form form .input-wrapper {
          width: 100%; } }
      @media only screen and (max-width: 480px) {
        .form form .input-wrapper:last-child {
          margin-top: 15px; } }
    .form form .input-wrapper .floating-label {
      position: absolute;
      pointer-events: none;
      top: 30px;
      left: 0;
      -webkit-transition: 0.2s ease all;
      -o-transition: 0.2s ease all;
      transition: 0.2s ease all;
      font-family: "Open Sans Regular";
      font-size: 16px;
      line-height: 40px; }
      @media only screen and (max-width: 480px) {
        .form form .input-wrapper .floating-label {
          font-size: 14px;
          line-height: 38px; } }
    .form form .input-wrapper input:focus ~ .floating-label,
    .form form .input-wrapper input:not(:focus):valid ~ .floating-label {
      top: 0; }
    .form form .inputs-wrapper {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between; }
      @media only screen and (max-width: 480px) {
        .form form .inputs-wrapper {
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; } }
    .form form input {
      display: block;
      width: 100%; }
      .form form input:not([type="submit"]) {
        height: 100px;
        background-color: transparent;
        border-bottom: 1px solid #fff;
        font-family: "Futura PT Book";
        font-size: 25px;
        line-height: 40px;
        color: #fff;
        padding-top: 25px; }
        @media only screen and (max-width: 480px) {
          .form form input:not([type="submit"]) {
            padding-top: 15px;
            height: 75px; } }
      .form form input[type="submit"] {
        background-color: #212121;
        font-size: 16px;
        line-height: 40px;
        color: #fff;
        width: 190px;
        height: 50px;
        border-radius: 25px;
        font-family: "Open Sans Regular";
        cursor: pointer; }
        @media only screen and (max-width: 600px) {
          .form form input[type="submit"] {
            margin-left: 10px;
            width: calc(50% - 5px); } }
      .form form input[type="radio"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0; }
    .form form .color--wrapper {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      font-family: "Open Sans Regular";
      font-size: 16px;
      line-height: 40px;
      color: #fff;
      margin-top: 45px; }
      @media only screen and (max-width: 480px) {
        .form form .color--wrapper {
          font-size: 13px;
          line-height: 20px; } }
      @media only screen and (max-width: 374px) {
        .form form .color--wrapper {
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; } }
      .form form .color--wrapper p {
        margin-right: 30px; }
        @media only screen and (max-width: 480px) {
          .form form .color--wrapper p {
            margin-right: 15px; } }
        @media only screen and (max-width: 374px) {
          .form form .color--wrapper p {
            margin-right: initial;
            text-align: center;
            width: 100%; } }
      .form form .color--wrapper .color-item {
        padding: 5px 0 5px 40px;
        position: relative;
        cursor: pointer; }
        @media only screen and (max-width: 480px) {
          .form form .color--wrapper .color-item {
            padding: 5px 0 5px 30px; } }
        @media only screen and (max-width: 374px) {
          .form form .color--wrapper .color-item {
            display: inline-block;
            margin: 10px; } }
        .form form .color--wrapper .color-item input:checked ~ .radioBtn {
          position: absolute;
          top: 13px;
          left: 12px;
          display: block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: #212121; }
          @media only screen and (max-width: 480px) {
            .form form .color--wrapper .color-item input:checked ~ .radioBtn {
              left: 9.5px; } }
        .form form .color--wrapper .color-item::before {
          content: " ";
          position: absolute;
          top: 50%;
          -webkit-transform: translateY(-50%);
              -ms-transform: translateY(-50%);
                  transform: translateY(-50%);
          left: 0;
          display: block;
          width: 30px;
          height: 30px;
          border-radius: 50%; }
          @media only screen and (max-width: 480px) {
            .form form .color--wrapper .color-item::before {
              width: 25px;
              height: 25px; } }
        .form form .color--wrapper .color-item:first-child {
          margin-right: 30px; }
          @media only screen and (max-width: 480px) {
            .form form .color--wrapper .color-item:first-child {
              margin-right: 5px; } }
          .form form .color--wrapper .color-item:first-child::before {
            background-color: #f477c0; }
        .form form .color--wrapper .color-item:last-child::before {
          background-color: #fff; }
  .form .advantages {
    position: absolute;
    bottom: 0;
    background-color: rgba(33, 33, 33, 0.32);
    padding: 40px 0;
    z-index: 1;
    width: 100%; }
    .form .advantages .container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between; }
      @media only screen and (max-width: 768px) {
        .form .advantages .container {
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; } }
    .form .advantages__item {
      width: calc(33.33334% - 30px);
      padding-left: 120px;
      position: relative;
      background-repeat: no-repeat; }
      @media only screen and (max-width: 768px) {
        .form .advantages__item {
          width: 100%;
          padding-left: 90px; } }
      .form .advantages__item:first-child {
        background-image: url(../img/delivery-icon.svg);
        background-position: 20px 35px; }
        @media only screen and (max-width: 768px) {
          .form .advantages__item:first-child {
            background-position: 12px 23px;
            background-size: 55px; } }
      .form .advantages__item:nth-child(2) {
        background-image: url(../img/wallet-icon.svg);
        background-position: 27px 23px; }
        @media only screen and (max-width: 768px) {
          .form .advantages__item:nth-child(2) {
            background-position: 17px 13px;
            background-size: 44px;
            margin-top: 20px; } }
      .form .advantages__item:last-child {
        background-image: url(../img/package-icon.svg);
        background-position: 32px 28px; }
        @media only screen and (max-width: 768px) {
          .form .advantages__item:last-child {
            background-position: 20px 16px;
            margin-top: 20px; } }
      .form .advantages__item::before {
        content: " ";
        display: block;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        border: solid 2px rgba(255, 255, 255, 0.4);
        position: absolute;
        left: 0;
        top: 0; }
        @media only screen and (max-width: 768px) {
          .form .advantages__item::before {
            width: 75px;
            height: 75px; } }
      .form .advantages__item-title {
        font-family: "Futura PT Book";
        font-size: 24px;
        line-height: 40px; }
        @media only screen and (max-width: 480px) {
          .form .advantages__item-title {
            font-size: 22px;
            line-height: 38px; } }
      .form .advantages__item-description {
        margin-top: 10px;
        font-family: "Open Sans Regular";
        font-size: 16px;
        line-height: 22px; }
        @media only screen and (max-width: 480px) {
          .form .advantages__item-description {
            font-size: 14px;
            line-height: 20px; } }

.footer {
  background-color: #261C19;
  padding: 15px 0;
  color: #fff;
  text-align: center;
  font-family: "Open Sans Regular";
  font-size: 16px;
  line-height: 22px; }
  .footer a {
    text-decoration: underline; }
    .footer a:hover {
      text-decoration: none; }

body {
  overflow-x: hidden; }

.container {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px; }

.section-title {
  font-family: "Futura PT Light";
  font-size: 34px;
  line-height: 40px;
  color: #212121;
  text-align: center;
  position: relative;
  margin-bottom: 75px; }
  .section-title::after {
    content: " ";
    display: block;
    height: 1px;
    width: 70px;
    background-color: #212121;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
    bottom: -20px; }
    @media only screen and (max-width: 480px) {
      .section-title::after {
        bottom: -10px; } }
  @media only screen and (max-width: 480px) {
    .section-title {
      font-size: 27px;
      line-height: 35px;
      margin-bottom: 35px; } }

.price__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  max-width: 555px;
  margin: 60px auto 0; }
  @media only screen and (max-width: 480px) {
    .price__row {
      margin: 30px auto 0; } }
  .price__row-block {
    width: 350px;
    height: 100px;
    border-radius: 50px;
    border: solid 1px rgba(234, 234, 234, 0.3);
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    @media only screen and (max-width: 600px) {
      .price__row-block {
        margin: 0 auto; } }
    @media only screen and (max-width: 480px) {
      .price__row-block {
        width: calc(50% - 5px);
        height: 50px; } }
    .price__row-block .old, .price__row-block .new {
      font-family: "Open Sans Light";
      font-size: 16px;
      line-height: 24px; }
      @media only screen and (max-width: 360px) {
        .price__row-block .old, .price__row-block .new {
          font-size: 14px;
          line-height: 22px; } }
      .price__row-block .old span, .price__row-block .new span {
        font-size: 28px;
        line-height: 40px; }
        @media only screen and (max-width: 480px) {
          .price__row-block .old span, .price__row-block .new span {
            font-size: 24px; } }
        @media only screen and (max-width: 374px) {
          .price__row-block .old span, .price__row-block .new span {
            font-size: 19px; } }
    .price__row-block .old {
      padding: 0 20px 0 40px; }
      @media only screen and (max-width: 400px) {
        .price__row-block .old {
          padding: 0 10px 0 30px; } }
      @media only screen and (max-width: 360px) {
        .price__row-block .old {
          padding: 0 10px 0 20px; } }
      .price__row-block .old span {
        display: inline-block;
        position: relative;
        font-family: "Futura PT Light"; }
        .price__row-block .old span::after {
          content: " ";
          display: block;
          height: 2px;
          width: 100%;
          position: absolute;
          top: 50%;
          -webkit-transform: translateY(-50%);
              -ms-transform: translateY(-50%);
                  transform: translateY(-50%);
          left: 0;
          background-color: #f75252; }
    .price__row-block .new {
      padding: 0 20px; }
      @media only screen and (max-width: 400px) {
        .price__row-block .new {
          padding: 0 10px; } }
      .price__row-block .new span {
        font-family: "Futura PT Book"; }
  .price__row .price__order {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #fff;
    color: #212121;
    width: 190px;
    height: 50px;
    border-radius: 25px;
    font-family: "Open Sans Regular"; }
    @media only screen and (max-width: 600px) {
      .price__row .price__order {
        margin-left: 10px; } }
    @media only screen and (max-width: 480px) {
      .price__row .price__order {
        width: calc(50% - 5px); } }
